@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Playfair+Display&display=swap');



@keyframes rise {
  from {opacity:0;transform:translatey(100%);}
  to {opacity:1;transform:translatey(0%);}
}

@keyframes fade {
  from {opacity:0;}
  to {opacity:1;}
}



@keyframes fall {
  from {opacity:1;transform:translatey(0%);}
  to {opacity:0;transform:translatey(100%);}
}
@keyframes fromRight {
  from {opacity:0;transform:translateX(100%);}
  to {opacity:1;transform:translateX(0%);}
}


@keyframes toRight {
  from {opacity:1;transform:translateX(0%);}
  to {opacity:0;transform:translateX(100%);}
}


@keyframes fromLeft {
  from {opacity:0;transform:translateX(-100%);}
  to {opacity:1;transform:translateX(0%);}
}


@keyframes toLeft{
  from {opacity:1;transform:translateX(0%);}
  to {opacity:0;transform:translateX(-100%);}
}

@keyframes bounce{
  from {transform:translateY(0%);}
  to {transform:translateY(-10%);}
}




@keyframes  image {
  from {
    transform:perspective(1700px) rotateY( -50deg  ) rotateX( 17deg ) translateX(-10%);
   
  }
  to {
    transform:perspective(1700px) rotateY( 0deg  ) rotateX( 0deg ) translateX(0%);
   
  }
}


 

@keyframes  imageInv {


0%{
  transform:perspective(1700px) rotateY( 0deg  ) rotateX( 0deg ) translateX(0%);
 
  }
100%{
  transform:perspective(1700px) rotateY( -50deg  ) rotateX( 17deg ) translateX(-10%);
 
  }

}



@keyframes spacing{
  0%{
    letter-spacing: 2ch;
    opacity: 0;
  }
  100%{
    letter-spacing: initial;
    opacity: 1;
  }
}


.ripple {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: fixed;
  border-radius: 50%;
  border: 1px solid #fffffff9;
  z-index: 10;
}

@keyframes ripple-effect {
  to {
     transform:scale(15);
     opacity: 0.01;
  }
}



* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
html {
 
 
 /*scroll-behavior: smooth;*/
 font-family: 'Montserrat', sans-serif;
 font-weight:400; font-style:normal;
}



body{
  font-family: 'Montserrat', sans-serif;
  font-weight:400; font-style:normal;
  font-size: 12px;
  overflow-x: hidden;
}

#root>div{
  position: relative;
}

html,body{
  width: 100vw;
 
}


h1{
  font-family: 'Montserrat', sans-serif;
  font-size: 2.488em;
  font-weight: 500;

}
h2{
  font-family: 'Montserrat', sans-serif;
  font-size:2.074em;
  font-weight: 500;
}
h3{
  font-family: 'Montserrat', sans-serif;
  font-size: 1.728em;
  font-weight: 500;
}
h4{
  font-family: 'Montserrat', sans-serif;
  font-size:1.44em;
  font-weight: 500;
}
 

:root{
   --accent:pink;
 

}



.big-video{
  
  height: 100vh;
  width: 100vw;
  scroll-snap-align:center;
  background: white;
  position: relative;
  z-index: 1;
 
}
.big-video video{
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  top:0;
  left:0;
  z-index: 0;
}



.intro-blurb{
position: absolute;
z-index: 1;
 
backdrop-filter: blur(20px);
padding:2em;
bottom:2em;

left:2em;
display: inline-block;
border-radius:1em;
color:white;
}

.intro-blurb h1{margin-bottom: .5em;}




.intro, .outro{
  height: 100vh;
  background: #fff;
  scroll-snap-align:center;
  display: grid;
  place-items: center;
  position: relative;
}

.outro .blurb{
  display: flex;
  flex-direction: column; 
  align-items: center;
  position: relative;
  z-index: 1;
}


.outro{
  position: relative;
  z-index: 1;
}
.outro .top{
display: flex;

}
.outro .top button{
  margin:.25em;
}
 .intro{
position: relative;
z-index: 1;
 }
 
.intro>div{
 padding: 1em;
}

.intro h1{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 4em;
}





.intro h1 span:nth-child(2){
  display: flex;
  align-items: center;
 margin:0 0 0 1ch;}
.intro>div svg{
  height: 2em;
  margin:0 1ch;
}

.intro .note{
  position: absolute;
  bottom:0;
  left:0;
  z-index: 1;
  padding:2em;
  
  max-width: 75ch;
}
.gif{
  height: 260px;
}


.project-wrapper{
 
  min-height: 100vh;
  width:100vw;
  display: flex;
}
/*
.wipe{
  position: fixed;
  top:0;left:0;
  
  background: white;
  min-height: 100vh;
  width:100vw;
  transform:translatey(100%);
  pointer-events: none;
  display: none;
}

.wipe.active{
  display: block;
  animation: rise .3s ease-out 0s 1  both;
}
*/




.back-button-wrapper{
  position: absolute;
  top:0em;
  left:0em;
  z-index: 2;
  height: 100%;
  animation: fromLeft .3s ease-out .3s 1  both;
  
}

.back .back-button-wrapper{
  pointer-events: none;
  animation: toLeft .3s ease-out .3s 1 both;
}



.back-button{
  position: sticky;
  top:1em;
  left:1em;
margin:1em ;
  cursor: pointer;
  background: black;
  padding:1em;
  border-radius: 100%;
  line-height: 0;
 z-index: 3;
}

.back-button svg{
  fill:white;
  height: 1em;
  width:1em;margin: 0;
}

button.back-button:hover svg{
  transform: translateX(0%);
   
}

.reel-wrapper button.back-button{
  background: #ffffff;
  color:black;
  mix-blend-mode: normal;
  position: absolute;
  left:100%;
  bottom:100%;
  top:auto;
  transition: .3s background;
  
  cursor: pointer;
}


.reel-wrapper button.back-button:hover{
  background: #ffffff55;
  transition: .3s background;
}
.reel-wrapper button.back-button svg{
  fill:black
}


.project-content{
   
  padding:1em;
  position: relative;
  display: flex;
    flex-wrap: wrap;
   
    border-bottom: solid 2px #f2f2f2;
}


.project-content h1{
  padding:1.5em 0 0;
  width:100%;
  text-align: center;
  background-color: #f2f2f2;
}


.project-content.ill img,.project-content.versa img,.project-content.branding img{
  width: 50%;
  flex:1;
  object-fit: cover;
}

.project-content.ill img:nth-child(9){
  width: 50%;
  object-fit: contain;
  background:   rgb(114,	139,	106	);
 
}
 

.project-content.versa img:nth-child(4), .project-content.versa .image-wrapper img{
  width: 100%;
  
}

.project-content.branding img:nth-child(9),.project-content.branding img:nth-child(9)~img{
  width: 100%;
}

.project-content.versa img:nth-child(2){
  object-position: 28%;
}
.project-content.versa .image-wrapper{
  background:rgb(202,10,55);
  width: 100%;
  display: grid;
  place-items: center;
}


.project-content.crayola>img:nth-child(1),.project-content.crayola>img:nth-child(2){
  width: 50%;
  flex:1;
  object-fit: cover;
}



.project-content .big-image{
  width: 100%;
  object-fit: cover;
  display: grid;
  place-items: center;
}

.project-content .big-image img{
  max-width: 100%;
 
}



.project-content.branding .big-image img{
 width: 100%;
 
}

.project-content.branding .gif-wrapper{
  width: 50%;
  /* flex: 1 1; */
  display: grid;
  place-items: center;
  background: rgb(241,241,241);
}

.project-content.branding .gif-wrapper img{
  max-width: 100%;
}



 .project-content.pelon .big-image ~img{
  width: 50%;
  flex:1;
  object-fit: cover;
}
 
.video-wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */ 
  }


  .video-wrapper iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    }

.foot-note-wrapper{
  
  position: sticky;
    bottom: 0;
    z-index: 0;
}
.foot-note{
  
  padding: 4em;
  max-width: 45ch;
  background: #ffffffd5;
  backdrop-filter: blur(10px);
  
}

 
.chestg img{
  width: 600px  !important;
  background: rgb(121,0,255);
background: radial-gradient(circle, rgba(121,0,255,1) 0%, rgba(26,0,126,1) 100%);
  
}
/*
html.snap body{
  
   scroll-snap-type: y mandatory;
 }
*/

.tridi-wrapper{
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  right:0;
  z-index: 0;
}

iframe.tridi{
  position: sticky;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right:0;
  z-index: 1; 
 opacity:.5;
mix-blend-mode:multiply;
 clip-path: polygon(100% 0, 100% calc(100% - 5em), calc(100% - 5em) calc(100% - 5em),calc(100% - 5em) 100%, 0 100%, 0 0);
 transition:.3s opacity;

}




.going .main-wrapper .list-wrapper>iframe{
  opacity: 0;
  transition:.3s opacity;

}


.main-wrapper{
  
background: white;
  position: relative;
  z-index: 1;}



.main-wrapper .list{

   position: relative;
   z-index: 1;}


   #arrow{
   
    position: absolute;
    bottom: 2em;
    left: 50%;
    font-size: 3em;
    font-weight: 500;
    animation: bounce .3s ease-in .2s infinite alternate;
   }

/*Desktop*/

@media only screen and (min-width: 681px) {

  html.snap{
    scroll-snap-type: y mandatory;
  }
.main-wrapper{
  transition: .3s background;

  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
}



.main-wrapper .content-wrapper{
  position: sticky;
  top:0;
  left:0;
  height: 100vh;
  overflow:hidden;
  width: 50vw;
  z-index: 1; 
  display: grid;
  place-items: center;
  order: -1;
  animation: fromLeft .3s ease-out both;
  pointer-events: none;
}


.main-wrapper .content-wrapper .content {
  max-width: calc(100% - 2em);
  margin: 2em;
  pointer-events: initial;
}



.main-wrapper .list-wrapper {
flex:1;
 
}


.main-wrapper .list{
 /* height: 100vh;*/
  transform-origin: right center;
  transform: translateZ(0px);
/*
*/
   /* mask-image: linear-gradient(to bottom, transparent 0%, black 25%, black 75%, transparent 100%);*/
  position: relative;
  z-index: 1;
  pointer-events: none;
}



.main-wrapper .list .list-item{
 display: block;
 
pointer-events: initial;
 
  transform:perspective(1700px) rotateY( -50deg  ) rotateX( 17deg ) translateX(-10%);
 
 perspective-origin: inherit;
 

  margin:3em 3em 3em auto;

  width:calc(60vw - 6em);
  height: calc(60vh - 6em);
 opacity: .5;
 clip-path: inset(4em);
 transition: .3s opacity, .3s clip-path ease-out;
 cursor: pointer;
}
.main-wrapper .list .list-item#item0{
  transition: .3s opacity, .3s clip-path ease-out, .3s transform;
}



.main-wrapper .list .list-item.active{

 opacity: 1;
  
  
  clip-path: inset(0% 0% 0% 0%);
 
  transition: .3s opacity, .6s clip-path ease-out;
}


.reel-wrapper~.main-wrapper .list .list-item{
 
 
  
  transform: perspective(1700px) rotateY( 0deg  ) rotateX( 0deg ) translateX(0%);
 
 
 
transition: .3s opacity, .3s clip-path ease-out, .3s transform;
} 




.main-wrapper .list .list-item img, .image-wrapper img{
height: 100%;
width: 100%;

object-fit: cover;
object-position: center center;
}


 
.main-wrapper .list>div  {
  height: 100vh;
  scroll-snap-align: center;
-webkit-scroll-snap-align: center;
  display: flex;
    align-items: center;
 }



.project-wrapper{
 
  height: 100vh;
  align-items: center;
  position: relative;
}

.project-wrapper .image-wrapper{
 
  transform:perspective(1700px) rotateY( -50deg  ) rotateX( 17deg ) translateX(-10%);
 
  position: relative;
  margin:3em 6em 3em auto;

animation: image .3s ease-out .3s 1  both;

  width:calc(60vw - 6em);
  height: calc(60vh - 6em);
  
}





.back .project-wrapper .image-wrapper{
  
  animation: imageInv .3s ease-out .3s 1 both;
}



.thumb-wrapper{
 
 
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100vh;
  /* align-items: center; */
  animation: fromRight  .3s   1  both;
  justify-content: center;
  opacity: 1;
  transition: .3s opacity;
}


.project-wrapper h1{
  
  max-width: 34%;
}




}

/*desktop end*/

.project-content.inf{
  align-items: flex-start;
}
.project-content.inf img{
  width: 100%;
  height: auto;
  
}
.going .main-wrapper .list .list-item.inactive{
  opacity: 0;
  transition: .3s opacity;
}



.going .thumb-wrapper{
  animation: toRight  .3s   1  both;
}



.going .main-wrapper .content-wrapper{
  animation: toLeft .3s ease-out both;
  
}
 




.project-wrapper h1{
  position: absolute;
  margin: 2em;
  animation: rise .3s ease-out .3s 1  both;
  z-index: 1;
 
}



.content a, .outro a {
  text-decoration: none;
}

.back .project-wrapper h1{
  opacity: 0;
  animation: fall .3s ease-out  1  both;
 }

button{
  display: flex;
  align-items: center;
  background: black;
  color:white;
  margin: 1em 0 0;
  padding: 1em;
  cursor: pointer;
  mix-blend-mode: multiply;
  transition: .3s background;
 

}

 button:hover{
background: #00000055;
transition: .3s background;
}

 button svg{
  height: 1em;
  margin:0 0 0 1ch;
  fill:white;
  transform: translateX(0%);
  transition: .3s transform;
}

 .content button:hover svg{
  transform: translateX(10%);
  transition: .3s transform;
}



.main-wrapper#active8 div{opacity: 0;

}

.main-wrapper#active0 div{opacity: 1;
  transition: .3s opacity .3s;

}

.main-wrapper#active0, #project0{
  
  background: #00FF7F; transition: .3s background;
 
 }

 .main-wrapper#active1, #project1{
  background: #c7a7ff; transition: .3s background;

  }


 .main-wrapper#active2, #project2{
 

  background: #00ffee; transition: .3s background;

  }
  .main-wrapper#active3, #project3{

   
    background: #f1f227; transition: .3s background;
  
  
  }
 
  .main-wrapper#active4, #project4{
   
  
   background: #ffaf2f; transition: .3s background;
  
  }
 
 
  .main-wrapper#active5, #project5{
    background: #ff6347;
   
    transition: .3s background;
  
  }
 
  .main-wrapper#active6, #project6{
   

   background: #ffb4df; transition: .3s background;
   transition: .3s background;
  }
 






.thumb-wrapper a{
  width:1em;
  height: 2em;
  background: white;
  box-shadow: 0 0 20px 0 #00000035;
  border-radius: 1em;
  display: inline-block;
  margin:1em 2em 1em 0;
  cursor: pointer;

  transition: .3s background;
}

.thumb-wrapper a.active{
  background: black;
  transition: .3s background;
}


.reel-wrapper{
  
  position: sticky;
  top:0;
  left:0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background: #000000d8;
  margin-bottom: -100vh;
  animation: fade .3s  both;
 
}

.reel-wrapper>div{
 position: relative;
}

.reel-wrapper .video-wrapper{
  width:min(640px, calc(100vw - 2em));
   
}
 
.Feed{display: flex;
flex-wrap: wrap;}

.Feed a img{
  width: calc(50vw - 1em)  !important;
}

iframe{
  max-width: calc(100vw - 2em);
}

.portrait img , .portrait video {
  max-height: 90vh;
}
.project-content.res .big-image, .project-content.misc .big-image {
  background: #f2f2f2;
padding: 3em 0;
overflow: hidden;
}


.project-content.res .big-image img{
  max-width: min(1080px, calc(100vw - 4em));
  box-shadow: 0 0 30px #00000025;


  
}

.project-content.res .big-image img.logo{
  max-width: min(512px, calc(100vw - 4em));
  
  box-shadow: none;
}
.project-content.res>h5{
  width: 100%;
  background-color: #f2f2f2;
  padding:1em 0 0 1em;
  text-align: center;
  font-size: 1.3em;
}

.side-by-side{
  display: flex;
  width: 100%;
  background: #f2f2f2;
  padding: 3em 3em;
  overflow: hidden;
  align-items: flex-start;
}

.side-by-side img{
  width: calc(50% - .5em);
 
}

.side-by-side img:nth-child(1){
  margin: 0 .5em 0 0 ;
}

.side-by-side img:nth-child(2){
  margin: 0 0 0 .5em ;
}

.masonry {
  background-color: #f2f2f2;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(20vw,1fr));
}

.masonry img{
  width:100%;
}




/*Mobile*/


@media only screen and (max-width: 1280px) {
  .main-wrapper .list-wrapper>iframe{
    pointer-events: none;
  }
}

@media only screen and (max-width: 680px) {

  .intro-blurb{
  
    padding:2em;
    bottom:4em;
    right:2em;
    left:2em;
 
    }


  .side-by-side{
    flex-direction: column;
    align-items: center;
  padding:0}
  .side-by-side img{
    margin: 1em 0 !important;
    width: calc(100vw - 4em);

    
  }
  

  .reel-wrapper button.back-button{
 
    right: 0;
    left: auto;
    bottom:100%;
    margin: 0 0 1em 0;
  }


  .gif{
    height: auto;
    width: 50%;
  }
  .back-button-wrapper {
   
    top: 0em;
    right: 0em;
    left:auto;
       display: flex; 
   align-items: flex-end;
}
  .back-button{
    
    bottom:1em;
   
   
  }


  .intro h1{
    text-align: center;
  }

  .main-wrapper .list .list-item{
 
   
    display: flex;
    
   }
 
  .main-wrapper .list .list-item img{
    width: 100vw;
    height: 60vh;
    object-fit: cover;
  }

.content-wrapper {
  position: sticky;
  bottom:0;
  left:0;
  padding:1em; 
  z-index: 2;
  bottom: 48px
  
}

.content button{
  margin: 1em 0 0 auto;
}

  .list>div{
    padding:0 0 40vh 0;
   
  }

  .thumb-wrapper{
    display: flex;
  justify-content: center;
    position: sticky;
    bottom: 0;
    background: transparent;
    z-index: 1;
    width: 100vw;
  }

  .thumb-wrapper a{
   
    margin:1em;
  }

  .Feed a img{
    width: calc(100vw - 2em)  !important;
  }



  .project-wrapper{
 
  flex-direction: column;
  }
  
  .project-wrapper h1{
  position: relative;
  margin:2em 2em 0;
  
  }
  
  
  .project-wrapper .image-wrapper{
    overflow: hidden;
    transform: none;
   
    position: relative;
    margin:0;
  
 
 

  }
 

  .project-content.crayola>img:nth-child(1),.project-content.crayola>img:nth-child(2),.project-content.ill img,.project-content.versa img{
    width: 100%;
    flex:1;
    object-fit: contain;
  }




  .project-content.versa iframe{
    width:100%
  }
  .project-wrapper .image-wrapper img{

      object-fit: cover;
    width:100vw;
    height:60vh;
    
  }

  .main-wrapper .content-wrapper{
    animation: rise .3s ease-out .3s 1  both;
  }

  .main-wrapper .list .list-item{
    
    opacity: .5;
    transition: .3s opacity;
        
    clip-path: inset(4em);
    transition: .3s opacity, .3s clip-path ease-out;
   }
   
   
   .main-wrapper .list .list-item.active{
   
    opacity: 1;
     
     
    clip-path: inset(0);
    transition: .3s .3s opacity, .3s .3s clip-path ease-out;
    
      
   }
   .project-content {
     align-items: flex-start;
   }




  .project-content.branding .gif-wrapper,   .project-content.branding img {width:100%;object-fit: contain;}

  .foot-note{
    padding: 1em 3em 1em 1em;
    width: calc(100% - 4em);
    
  }

  #item0 img{
    object-position:right center ;

  }
   #item2 img,#project2 .image-wrapper img{
    object-position:left center ;

  }
 
}




@media only screen and (max-width: 680px) {

}